import "./center.css"

function Center() {
  return (
    <>
    <div className="general">
    <div className="projContainer">
        <p className="projText">Recent Projects</p>

        <a href="/EG" className="links3"><td>Evil Genius: Data Science Project</td></a>
        <td className="text5">May 28, 2023</td>
        <br></br>
        <a href="/openstreetmap" className="links3"><td>OpenStreetMap: API/Web Devlopment</td></a>
        <td className="text5">July 29, 2023</td>
        <br></br>
        <a href="/valorant" className="links3"><td>Valorant: Data Science Project</td></a>
        <td className="text5">July 27, 2023</td>
        <br></br>
        <a href="/mern" className="links3"><td>Authentication: MERN Stack</td></a>
        <td className="text5">June 10, 2023</td>
        
    </div>

    <div className="textContainer">
      <br></br>
        <p className="testings">Hello there, whoever you are, and whatever the reason may be, welcome to my personal website!</p>
        <p className="testings">My name is Kevin Le, and this is my website, showcasing some of my projects, my socials, and my portfolio (highlighting some of my interests and hobbies)!</p>
        <p className="testings">Currently, I am exploring my interests in web devleopment and data science. Some of my projects, along with documentation, are to the left! </p>
        <p className="testings">If you are curious, continue exploring my website, as it entails my academic and professional interests!</p>
        <img src="kevin_le.jpeg" width="100px" className="kevin_img" ></img>

    </div>
    </div>
    </>
  );
}

export default Center;
